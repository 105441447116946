$(document).ready(function() {
    /*
    Infinite Scroll
     */
    $.fn.matchHeight._maintainScroll = true;
    var $nextLinkHome = $('.infinite-tiles-home');
    var $nextLink = $('.pagination__next');
    if (!$nextLink.length && !$nextLinkHome.length) {
        $('.view-more-button').hide();
    }

    var infiniteIncrement = $('.post > div').length;
    var $container = $('.infinite-tiles').infiniteScroll({
        path: function () {
            if ($(this.element).hasClass('infinite-tiles-home')) {
                var pageNumber = this.loadCount * 8;
                return '/site/infinite_scroll/P' + pageNumber;
            } else if ($(this.element).hasClass('infinite-tiles-search')) {


                var pageNumber = (this.loadCount + 1) * 10;
                var query_search = $('input:hidden[name=query_search]').val();
                //var query_next = $('input:hidden[name=query_next]').val();
                var query_section = $('input:hidden[name=query_section]').val();


                return '/search/more/?q=' + query_search + '&start=' + pageNumber + '&section=' + query_section;
            } else {
                var pageNumber = (this.loadCount + 1) * infiniteIncrement;

                if ($(this.element).hasClass('is-tools'))
                    pageNumber = pageNumber + 1;
                    
                if ((window.location.href.length - 1) != window.location.href.lastIndexOf('/')) {
                    window.location.href += '/';
                }
                if ($(this.element).find('.pagination__next').length > 0) {
                    return 'P' + pageNumber + '/';
                }
            }
        },
        append: '.post',
        button: '.view-more-button',
        status: '.page-load-status',
        checkLastPage: '.pagination__next',
        history: false,
    });
    var infScroll = $container.data('infiniteScroll');
    $container.on('load.infiniteScroll', onPageLoad);

    function onPageLoad() {
        
        if ($('.sticky-container, .vertical-ad').css('position') == 'absolute') {
            $('.sticky-container, .vertical-ad').attr('style', 'position: fixed; top: 16px; width: 300px;');
        }
        if (infScroll.loadCount == 1) {
            if (typeof ga === 'function'){
                ga('send', 'event', 'load-more', 'lazyload', infScroll.getPath());
            }
            if (typeof gtag === 'function'){
                gtag("event", "lazyload", {
                    event_category: "load-more",
                    event_label: infScroll.getPath(),
                  });
            }
            $container.infiniteScroll('option', {
                loadOnScroll: false,
            });
        }else{
            var $el = $(".post").last();
            var bottom = $el.offset().top + $el.outerHeight(true);

            $([document.documentElement, document.body]).animate({
                scrollTop: bottom
            }, 1000);
        }
    }

    $container.on('append.infiniteScroll', function () {
        $('main').removeAttr('style');
        setTimeout(function () {
            $('#wallpaper').matchHeight({
                target: $('main')
            });
            $('#wallpaper').height($('#wallpaper').height() + 70);
        }, 100);
    });

});
